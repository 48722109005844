import { Fade } from "react-awesome-reveal";
import GoToTop from '../GoToTop'
import HistoryCarousel from '../HistoryCarousel'

import CarCutout from '../../assets/HistoricCarPhotos/UT12/UT12-Cutout.png'
import OverviewImg from '../../assets/HistoricCarPhotos/UT12/2012-Competition-2.jpg'
import CompImg1 from '../../assets/HistoricCarPhotos/UT12/2012-Competition-1.jpg'
import CompImg2 from '../../assets/HistoricCarPhotos/UT12/2012-Competition-4.JPG'
import CompImg3 from '../../assets/HistoricCarPhotos/UT12/2012-Competition-6.jpg'
import CarouselImg1 from '../../assets/HistoricCarPhotos/UT12/2012-Cad-1.jpg'
import CarouselImg2 from '../../assets/HistoricCarPhotos/UT12/2012-Car-1.JPG'
import CarouselImg3 from '../../assets/HistoricCarPhotos/UT12/2012-Competition-3.JPG'
import CarouselImg4 from '../../assets/HistoricCarPhotos/UT12/2012-Competition-5.JPG'
import CarouselImg5 from '../../assets/HistoricCarPhotos/UT12/2012-Shop-1.JPG'
import CarouselImg6 from '../../assets/HistoricCarPhotos/UT12/2012-Shop-2.JPG'
import CarouselImg7 from '../../assets/HistoricCarPhotos/UT12/2012-Team-1.jpg'
import TeamMembersSimpleComponents from "./TeamMembersSimpleComponent";

const UT12 = () => {
    const slides = [
        {
            image: CarouselImg1,
            description: ''
        },
        {
            image: CarouselImg2,
            description: ''
        },
        {
            image: CarouselImg3,
            description: ''
        },
        {
            image: CarouselImg4,
            description: ''
        },
        {
            image: CarouselImg5,
            description: ''
        },
        {
            image: CarouselImg6,
            description: ''
        },
        {
            image: CarouselImg7,
            description: ''
        },
    ];

    const teamMembers = [
        { name: "Michael Bakaic", specialization: "- Team Principal, Technical Director, General Systems Lead" },      
        { name: "Kevin Ramsaran", specialization: "- Brakes & Chassis Lead" },      
        { name: "Demitra assos", specialization: "- Engine Lead" },      
        { name: "Vivek Subbayya", specialization: "" },      
        { name: "Luke Fregonese", specialization: "" },      
        { name: "Vivek Chugh", specialization: "" },      
        { name: "Wilfred Mok", specialization: "" },      
    ];

  return (
    <div>
        {/* Header */}
        <div className="h-screen bg-[#B6C1D0]">
            <Fade cascade damping={0.2} triggerOnce>
            <h1 className="carTitle text-center text-[130px] md:text-[280px] font-bold text-white pt-[250px]">UT12</h1>
            <img className="mx-auto w-[1000px]" src={CarCutout} alt="" />
            <div className='grid space-y-8 md:space-y-0 md:grid-cols-3'>
                <div>
                    <h2 className="text-[#3A4254] text-center text-xl md:text-2xl font-bold">0 - 100 km/h</h2>
                    <h2 className="text-center text-white text-3xl md:text-4xl font-bold">3.5 s</h2>
                </div>
                <div>
                    <h2 className="text-[#3A4254] text-center text-xl md:text-2xl font-bold">Top Speed</h2>
                    <h2 className="text-center text-white text-3xl md:text-4xl font-bold">120 km/h</h2>
                </div>
                <div>
                    <h2 className="text-[#3A4254] text-center text-xl md:text-2xl font-bold">Lateral Acceleration</h2>
                    <h2 className="text-center text-white text-3xl md:text-4xl font-bold">1.8 g (50-ft skidpad)</h2>
                </div>
            </div>
            </Fade>
        </div>

        {/* Overview Section */}
        <div className='bg-[#303949] grid lg:grid-cols-2 text-white'>
            <div className='px-10 py-10 lg:px-24 lg:py-32'>
                <Fade cascade damping={0.2} triggerOnce>
                <h1 className='text-4xl md:text-6xl font-bold pb-10'>OVERVIEW</h1>
                <p>In 2012, UT12 made significant strides in engineering innovation. Despite challenges faced, the team achieved notable successes, clinching 1st place in the cost category at both Formula SAE Michigan and Formula North. Powered by a Honda TRX450ER single-cyliner engine, UT12 showcased impressive acceleration reaching 0 - 100 km/h in 3.5 seconds with a top speed of 120 km/h. The vehicle's full steel tube space frame chassis with a push rod, double unequal length A-Arm type suspension, the car was sturdy yet agile, weighing a mere 350 lbs. Noteworthy achievements such as the use of aluminimum welded uprights and the car being Toronto's cheapest car, underscored the team's resourcefulness and ingenuity.</p>
                </Fade>
            </div>
            <div className='mx-auto my-auto px-10 pb-10 lg:pt-10 lg:pr-24'>
                <Fade cascade damping={0.2} triggerOnce>
                <img src={OverviewImg} alt="" />
                </Fade>
            </div>
        </div>

        {/* Competitions Section */}
        <div className='bg-[#062644] text-white'>
            <Fade cascade damping={0.2} triggerOnce>
            <h1 className='text-4xl md:text-6xl font-bold py-10 text-center'>COMPETITIONS</h1>
            <div className='grid lg:grid-cols-3 items-center pb-10'>
                <div className='px-10'>
                    <div className='competitionCard h-[400px]' style={{
                        backgroundImage: `url(${CompImg1})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionY: "10%"
                        }}>
                    </div>
                    <Fade cascade damping={0.2} triggerOnce>
                    <h3 className='text-center text-2xl pt-5'>Formula SAE Michigan</h3>
                    <h3 className='text-center lg:pb-0 text-2xl font-bold'>77th Place Overall</h3>
                    <h3 className='text-center text-2xl font-bold pb-10 lg:pb-0'>Cost - 1st Place</h3>
                    </Fade>
                </div>
                <div className='px-10'>
                    <div className='competitionCard h-[400px]' style={{
                        backgroundImage: `url(${CompImg2})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionY: "80%"
                        }}>
                    </div>
                    <Fade cascade damping={0.2} triggerOnce>
                    <h3 className='text-center text-2xl pt-5'>Formula Student Germany</h3>
                    <h3 className='text-center pb-10 text-2xl font-bold'>66th Place Overall</h3>
                    <h3 className='hidden text-center text-2xl font-bold'>RESULT</h3>
                    </Fade>
                </div>
                <div className='px-10'>
                    <div className='competitionCard h-[400px]' style={{
                        backgroundImage: `url(${CompImg3})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionY: "80%"
                        }}>
                    </div>
                    <Fade cascade damping={0.2} triggerOnce>
                    <h3 className='text-center text-2xl pt-5'>Formula North</h3>
                    <h3 className='text-center pb-0 lg:pb-10 text-2xl font-bold'>Cost - 1st Place</h3>
                    </Fade>
                </div>
            </div>
            </Fade>
        </div>

        {/* Team Members Section */}
        <TeamMembersSimpleComponents teamMembers={teamMembers} year={"UT12"} />

        
        {/* Photo Gallery Section */}
        <div className='bg-[#13191F] px-10 md:px-0'>
            <div className=''>
            <Fade cascade damping={0.2} triggerOnce>
                <h1 className='text-4xl text-white pt-10 text-center md:text-6xl pb-5 flex justify-center font-bold'>PHOTO GALLERY</h1>
                <div className='lg:px-52 pb-5'>
                    <HistoryCarousel slides={slides}/>
                </div>
            </Fade>
            </div>
        </div>

        {/* Technical Specifications Section */}
        <div className=' bg-[#062644]'>
            <Fade cascade damping={0.2} triggerOnce>
            <h1 className='text-4xl text-white pt-10 text-center md:text-6xl pb-5 flex justify-center font-bold'>TECHNICAL SPECIFICATIONS</h1>
            <div className='flex text-white justify-center pb-10'>
                <div className='w-[850px] pb-3 px-5'>
                    <Fade cascade damping={0.1} triggerOnce>
                    <div className='flex border-b-2'>
                        <p className='md:text-2xl py-5'>Engine</p>
                        <p className='md:text-2xl py-5 ml-auto'>Honda TRX450ER single cylinder</p>
                    </div>
                    <div className='flex border-b-2'>
                        <span className='hidden md:inline md:text-2xl py-5'>0 - 100 km/h</span>
                        <p className='md:text-2xl py-5 ml-auto'>3.5 s</p>
                    </div>
                    <div className='flex border-b-2'>
                        <p className='md:text-2xl py-5'>Top Speed</p>
                        <p className='md:text-2xl py-5 ml-auto'>120 km/h</p>
                    </div>
                    <div className='flex border-b-2'>
                        <p className='md:text-2xl py-5'>Lateral Acceleration</p>
                        <p className='md:text-2xl py-5 ml-auto'>1.8 g (50-ft skidpad)</p>
                    </div>
                    <div className='flex border-b-2'>
                        <p className='md:text-2xl py-5'>Weight</p>
                        <p className='md:text-2xl py-5 ml-auto'>350 lbs</p>
                    </div>
                    </Fade>
                </div>
            </div>
            </Fade>
        </div>
        <GoToTop />
    </div>
  )
}
export default UT12
